import Radio from 'components/forms/Radio'
import Loader from 'components/feedback/Loader'
import Button from 'components/forms/Button'
import Input from 'components/forms/Input'
import { addCustomerToList } from 'thirdparty/Rejoiner/Rejoiner.service'
import { useForm, Controller } from 'react-hook-form'
import { useState } from 'react'
import Style from './RejoinerEmailToaster.module.scss'


function SubmissionForm({ 
    onSuccess = () => null 
}){
    const [ loading, setLoading ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [ err, setError ] = useState(false)


    const { handleSubmit, control } = useForm({
        defaultValues: {
            email: "",
        }
    });

    function postForm(formData){
        setLoading(true)

        addCustomerToList({ 
            data: JSON.stringify(formData), 
            list: process.env.NEXT_PUBLIC_rejoiner_modal_list 

        }).then((response) => {
            if( response?.customer ){
                setLoading(false)
                setSuccess(true)
                onSuccess()

            } else {
                setLoading(false)
                setError(true)
            }

        }).catch(err => {
            setError(true)
            setLoading(false)
        })

    }


    return(
        <>
            <p className={Style.heading}>
                Your Chance to Win a
            </p>

            <p className={Style.callOut}>
                $250 Gift Card
            </p>

            <p className={Style.text}>
                Enter to win a $250 gift card when you sign up for emails&mdash;and be first to know about exclusive savings and new releases.
            </p>

            <form onSubmit={handleSubmit(postForm)} className={Style.form}>             
                <div className={Style.control}>
                    <Controller
                        name="email"
                        control={control}
                        required
                        render={({ field }) => (
                            <Input
                                {...field} 
                                type="email"
                                required
                                autoFocus
                                label="Email Address*"
                                placeholder="you@example.com"
                            />
                        )}
                    />
                </div>

                <div className={Style.control}>
                    <Controller
                        name="customer_type"
                        control={control}
                        render={({ field }) => (
                            <Radio 
                                {...field}
                                label="I'm a trade professional"
                                value="trade"
                            />
                        )}
                    />

                    <Controller
                        name="customer_type"
                        control={control}
                        render={({ field }) => (
                            <Radio 
                                {...field}
                                label="I'm a retail customer"
                                value="retail"
                            />
                        )}
                    />
                </div>

                <div className={Style.control}>
                    <Button 
                        type="submit" 
                        color="ocean"
                        size="full"
                        className={Style.submitBtn}
                    >
                        {(loading || success || err) ? 
                            <Loader 
                                isLoading={loading} 
                                isSuccessful={success} 
                                hasError={err} 
                            /> 
                        : 
                            `Enter to win`
                        }
                    </Button>
                </div>
            </form>
        </>
    )
}

export default SubmissionForm;