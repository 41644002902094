/* eslint-disable react-hooks/exhaustive-deps */
import { HeaderDropdownContext } from "components/applications/header_footer/Header/contexts/HeaderContext";
import { useContext, useEffect, useState } from 'react';
import { usePathname } from "next/navigation";



export default function useDropDown({ isHandheld, hideFlyout, isMoreButton = false }){
    const pathName = usePathname();
    const headerCtx = useContext(HeaderDropdownContext)
    const [ dropDown, setDropDownShown ] = useState(false);
    let timeout = null;


    const clear = () => {
        if( typeof headerCtx.setDropDownShown === "function" ){
            clearTimeout(timeout);

            timeout = setTimeout(() => { 
                setDropDownShown(false) 
                headerCtx.setDropDownShown(false) 

            }, 300) 
        } 
    }




    const show = () => {
        if( (!isHandheld || isMoreButton) && typeof headerCtx.setDropDownShown === "function" ){
            clearTimeout(timeout);

            // if( headerCtx.showUnderlay && isHandheld ){
            //     headerCtx.updateOverlay(false)
            // }

            timeout = setTimeout(() => { 
                setDropDownShown(true) 
                headerCtx.setDropDownShown(true) 

            }, 750)
        }
    }



    useEffect(() => {
        if( timeout && !hideFlyout ){
            clear()
        }

    }, [ hideFlyout, timeout ])




    // clean up open flyout on page transitions
    useEffect(() => {
        clear()
       
    }, [pathName])



    return{
        isShown: dropDown,
        show,
        clear
    }

}