"use client"

import Link from 'next/link';
import Chat from 'components/help/Chat';
import { IconBlog } from 'components/icons/IconBlog';
import { IconLocation } from 'components/icons/IconLocation';
import { IconLogoOcean } from 'components/icons/IconLogoOcean';
import { IconMobileCall } from 'components/icons/IconMobileCall';

import Style from 'components/applications/header_footer/Footer/styles/Footer.module.scss';
import useCallTracking from 'thirdparty/CallTracking/useCallTracking';

function UpperNav() {
    const callRailPh = useCallTracking();

    return (
        <div className={Style.upper}>
            <Link href="/" className={Style.logoCntr} title="authenteak.com">

                <IconLogoOcean className={Style.logo} width="210" height="35" />

            </Link>
        
            <Link href="/c/store-locator" className={Style.callOut} title="Visit us">

                <IconLocation width="30" height="30" />
                <span className={Style.callText}>
                    Store Locator<br/>
                    Atlanta Showroom
                </span>

            </Link>
            
            <Link href="/blog" className={Style.callOut} title="Our blog">

                <IconBlog width="30" height="30" />
                <span className={Style.callText}>
                    Get Inspired:<br/>
                    Check out our blog
                </span>

            </Link>
            
            <span className={Style.callOut}>
                <a href={`tel:${callRailPh}`} title="calls us" style={{ display: "flex" }}>
                    <IconMobileCall width="30" height="30" />
                    <span className={Style.callText}>
                        Call Us:<br/>
                        {callRailPh}
                    </span>
                </a>
            </span>


            <Chat 
                type="footer"
                className={Style.callOut} 
            />
        </div>
    );
}

export default UpperNav;