
export function IconLogoPro(props){
    return(
        <svg xmlSpace="preserve" viewBox="0 0 1080 149" {...props} aria-label="AuthenTEAK Pro">
            <g transform="translate(428.85 78.764)" fill="#2E303B">
                <path d="m-411.1-20.7c1.6-3.4 4-6.4 7-8.7 3.1-2.3 6.6-4 10.4-5 4.3-1.1 8.7-1.7 13.2-1.6 3.7 0 7.3 0.4 10.9 1 3.5 0.6 6.8 2 9.8 3.9 3 2.1 5.4 4.8 7.1 8.1 2 4.2 2.9 8.9 2.7 13.6v44c-0.5 2.9 1.5 5.6 4.4 6.1 0.5 0.1 1 0.1 1.5 0 1.1 0 2.2-0.2 3.2-0.7v8.5c-1.2 0.2-2.2 0.4-3.1 0.5-1.1 0.1-2.3 0.2-3.5 0.2-2.1 0.1-4.2-0.3-6.1-1-1.4-0.6-2.7-1.7-3.6-3-0.9-1.4-1.5-2.9-1.7-4.6-0.3-1.9-0.4-3.9-0.4-5.9h-0.3c-1.7 2.5-3.5 4.9-5.5 7.2-1.8 2-3.9 3.8-6.3 5.2-2.4 1.5-5.1 2.5-7.9 3.2-3.5 0.8-7 1.2-10.5 1.1-3.7 0-7.3-0.4-10.8-1.4-3.2-0.8-6.2-2.3-8.8-4.3-2.5-2-4.6-4.6-5.9-7.5-1.5-3.4-2.2-7.1-2.2-10.8-0.3-4.8 1.1-9.6 3.9-13.6 2.7-3.3 6.2-5.9 10.2-7.5 4.6-1.8 9.4-3 14.3-3.6 5.3-0.7 10.7-1.4 16.1-2 1.9-0.2 3.8-0.4 5.6-0.8 1.4-0.2 2.8-0.8 4-1.7 1.1-0.9 2-2 2.5-3.3 0.7-1.7 1-3.6 0.9-5.5 0.1-2.9-0.5-5.8-1.7-8.4-1.1-2.1-2.7-3.9-4.7-5.2-2.1-1.3-4.5-2.2-6.9-2.6-2.8-0.5-5.6-0.7-8.5-0.7-5.6-0.2-11.2 1.4-15.8 4.6-4.3 3.6-6.7 9-6.4 14.7h-10.1c0.1-4.3 1.1-8.5 3-12.5m50.6 24.8c-0.8 1.3-2.2 2.2-3.7 2.6-1.6 0.5-3.2 0.9-4.8 1.1-4.3 0.8-8.7 1.4-13.3 2-4.2 0.5-8.4 1.4-12.5 2.7-3.4 1-6.6 2.7-9.2 5.1-2.5 2.5-3.8 5.9-3.6 9.4-0.1 2.4 0.5 4.7 1.5 6.8 1 2 2.4 3.7 4.1 5.1 1.7 1.5 3.8 2.6 5.9 3.3 2.2 0.7 4.6 1.1 6.9 1.1 3.8 0 7.5-0.6 11.1-1.8 3.4-1.1 6.5-2.9 9.2-5.2 5.6-4.8 8.7-11.8 8.6-19.1v-13.1h-0.2z"/>
                <path d="m-272.8 49.3-0.1-14.9h-0.3c-2.5 5.3-6.5 9.8-11.6 12.7-5.2 3-11 4.5-16.9 4.4-4.6 0.1-9.1-0.6-13.4-2.2-3.5-1.3-6.5-3.4-9-6.2-2.4-2.8-4.1-6.2-5.1-9.8-1.1-4.3-1.7-8.7-1.6-13.2v-53.8h10.1v53.9c-0.2 6.1 1.6 12.1 5.2 17 3.3 3.9 8.9 5.8 17 5.8 3.9 0.1 7.8-0.9 11.2-2.8 3.1-1.9 5.7-4.4 7.7-7.5 2.1-3.3 3.6-6.9 4.5-10.7 1-4 1.4-8.1 1.4-12.3v-43.5h10.1v82.8h-9.2v0.3z"/>
                <path d="m-212.3-33.5v8.5h-16.8v55.9c-0.1 2.7 0.3 5.3 1.4 7.8 0.9 1.9 3.2 2.9 6.8 3.1 2.9 0 5.8-0.2 8.7-0.5v8.5c-1.5 0-3 0.1-4.5 0.2s-3 0.2-4.5 0.2c-6.7 0-11.5-1.3-14.1-3.9-2.7-2.6-4-7.5-3.8-14.5l0.1-56.7h-14.5v-8.5h14.5v-24.9h10.1v25h16.6v-0.2z"/>
                <path d="m-188.5-65.3v46h0.3c2-5.1 5.6-9.4 10.3-12.1 4.9-3 10.5-4.6 16.2-4.6 4.9-0.1 9.9 0.6 14.5 2.3 3.6 1.3 6.8 3.5 9.4 6.3 2.4 2.9 4.2 6.3 5 10 1 4.4 1.5 8.8 1.5 13.3v53.3h-10.1v-51.6c0-3.3-0.3-6.6-1-9.9-0.6-2.9-1.7-5.6-3.4-7.9s-3.9-4.1-6.5-5.3c-3.2-1.4-6.7-2-10.2-1.9-3.7-0.1-7.4 0.7-10.8 2.2-3.1 1.4-5.8 3.4-8 5.9-2.3 2.7-4 5.7-5.1 9-1.3 3.6-1.9 7.5-2 11.3v48.3h-10.1v-114.6h10z"/>
                <path d="m-108.7 21.9c0.9 3.8 2.6 7.3 4.9 10.5 2.3 3.1 5.2 5.8 8.5 7.7 3.8 2.1 8 3.2 12.4 3.1 6.3 0.4 12.5-1.7 17.3-5.8 4.4-4.2 7.4-9.5 8.7-15.4h10.1c-1.5 8.3-5.7 16-11.8 21.8-5.7 5.1-13.8 7.7-24.3 7.7-5.8 0.1-11.6-1-16.9-3.5-4.6-2.2-8.6-5.4-11.6-9.5-3.1-4.2-5.3-8.9-6.7-14-1.5-5.5-2.2-11.1-2.2-16.8 0-5.4 0.7-10.8 2.2-16.1 1.3-5 3.6-9.8 6.7-14s7-7.6 11.6-9.9c5.2-2.6 11.1-3.9 16.9-3.8 11.4-0.4 22.1 5.1 28.5 14.5 3 4.5 5.2 9.6 6.3 14.9 1.3 5.6 1.8 11.3 1.6 17.1h-63.7c0 4 0.5 7.8 1.5 11.5m50.1-30.9c-1.2-3.4-3-6.6-5.2-9.4-2.3-2.7-5-5-8.2-6.6-3.4-1.7-7.1-2.6-10.9-2.5-3.8-0.1-7.6 0.8-11.1 2.5-3.2 1.6-5.9 3.8-8.2 6.6s-4.1 6-5.4 9.5-2.2 7.2-2.7 11h53.6c0-3.8-0.7-7.5-1.9-11.1"/>
                <path d="m-23.6-33.5v14.3h0.3c1.9-5.1 5.6-9.4 10.3-12.1 4.9-3 10.5-4.6 16.2-4.6 4.9-0.1 9.8 0.6 14.5 2.3 3.6 1.3 6.8 3.5 9.4 6.3 2.4 2.9 4.1 6.3 5 10 1 4.4 1.5 8.8 1.5 13.3v53.3h-10.2v-51.7c0-3.3-0.3-6.6-1-9.9-0.6-2.8-1.7-5.5-3.4-7.9-1.7-2.3-3.9-4.1-6.5-5.3-3.2-1.4-6.7-2-10.2-1.9-3.7-0.1-7.4 0.7-10.8 2.2-3.1 1.4-5.8 3.4-8 5.9-2.3 2.6-4 5.7-5.1 9-1.3 3.6-1.9 7.5-2 11.3v48.3h-10.1v-82.8h10.1z"/>
                <path d="m115.8-35.2v6.9h-32.7v77.6h-8.9v-77.6h-32.6v-6.9h74.2z"/>
                <path d="m115.8-35.2v6.9h-32.7v77.6h-8.9v-77.6h-32.6v-6.9h74.2z"/>
                <path d="M194-35.2v6.9h-55.8V2.1h52.3v7h-52.3v33.3h56.5v7h-65.3v-84.5H194V-35.2z"/>
                <path d="m249.1-35.2 36.8 84.5h-9.6l-11.4-26.2h-42.7l-11.3 26.2h-9.5l37.7-84.5h10zm12.8 51.3-18.1-43.3-18.8 43.3h36.9z"/>
                <path d="m249.1-35.2 36.8 84.5h-9.6l-11.4-26.2h-42.7l-11.3 26.2h-9.5l37.7-84.5h10zm12.8 51.3-18.1-43.3-18.8 43.3h36.9z"/>
                <path d="m308-35.2v45.4l54.5-45.4h12.1l-41.8 34.9 43.9 49.6h-11.6l-39-44-18 15v29h-8.8v-84.6l8.7 0.1z"/>
                <path d="m308-35.2v45.4l54.5-45.4h12.1l-41.8 34.9 43.9 49.6h-11.6l-39-44-18 15v29h-8.8v-84.6l8.7 0.1z"/>
            </g>
            <g fill="#4C7B9A">
                <path d="m841 43.6h30.3c10.5 0 14.1 0.6 18.5 3 6.6 3.7 10.4 11.1 10.4 20.6 0 10.8-4.8 18.6-13.5 21.9-4.1 1.5-8.2 2-18.1 2h-19.1v36.9h-8.5v-84.4zm27.4 40.2c7.8 0 11.1-0.3 14.1-1.3 5.9-2 9.2-7.5 9.2-15.4 0-7.7-2.8-12.7-8.4-14.8-2.8-1-6.1-1.4-11.8-1.4h-22.2v32.9h19.1z"/>
                <path d="m914.4 43.6h34.2c9 0 14.2 0.9 18.1 3.2 6.5 3.5 10.1 10.8 10.1 19.6 0 9.7-4.2 16.2-13 20.1 8.6 4.8 9.5 7.1 10.4 24.3 0.9 13.3 1 13.5 4.8 17.2h-10.1c-1.4-4.1-1.6-5.2-2.2-13.5-0.9-13.8-2.4-19.4-6.2-22.2-2.7-2-5.2-2.4-14.1-2.4h-23.7v38.1h-8.4v-84.4h0.1zm31 39.5c6.3 0 10.1-0.4 13-1.3 6.2-1.9 9.9-7.6 9.9-15.6 0-7.8-3.7-13-10.5-14.7-2.4-0.5-5.6-0.8-12.2-0.8h-22.9v32.3l22.7 0.1z"/>
                <path d="m1060.1 56.4c5.8 8.1 8.7 18.4 8.7 30.3 0 26.2-15.2 43-38.9 43-24.2 0-39.1-16.7-39.1-43.7 0-11.1 3.2-21.8 8.7-29.6 7.1-9.9 16.7-14.4 30.3-14.4 13.9-0.1 23.4 4.5 30.3 14.4zm-60.7 29.2c0 23 11.5 36.8 30.6 36.8 18.7 0 30.4-13.9 30.4-36.3 0-23-11.5-37-30.5-37-18.9 0-30.5 14-30.5 36.5z"/>
            </g>        
        </svg>
    )
}