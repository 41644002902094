import { IconMenu } from 'components/icons/IconMenu';
import PropTypes from 'prop-types';

// import Style from './MenuBtn.module.scss';

function MenuBtn({ 
    toggleFlyout = () => null, 
    isImmobile, 
    Style 
}){
    return(
        <button 
            type="button" 
            className={Style.block} 
            onClick={toggleFlyout}
            style={{ marginRight: !isImmobile && 0 }}
        >
            <IconMenu width="30" height="30" />
        </button>
    )
}

MenuBtn.propTypes = {
    toggleFlyout: PropTypes.func
};


export default MenuBtn;