"use client"

import PropTypes from 'prop-types';
import Link from 'next/link';
// import header from './HeaderLogo.module.scss';
import { IconLogoOcean } from 'components/icons/IconLogoOcean';
import { IconLogo20Yrs } from 'components/icons/IconLogo20Yrs'
import { IconLogoPro } from 'components/icons/IconLogoPro'


function HeaderLogo({
    isPortrait,
    isTablet, 
    isMobile,
    user,
    Style,
    isCustomLogo = null
}){

    return (
        <div className={Style.block} role="banner" suppressHydrationWarning={true}> 
             <Link href="/" className={Style.link} title="Authenteak.com">
                {isCustomLogo ? (
                    <IconLogo20Yrs className={Style.logo} />
                ):(
                    <>
                        {user.customer_group_id === 1 ? (
                            <IconLogoPro className={Style.logo} />
                        ):(
                            <IconLogoOcean 
                                className={Style.logo} 
                                width={isMobile || (isTablet && isPortrait) ? 150 : 300} 
                                height="50" 
                            /> 
                        )}
                    </>
                )}
                
             </Link>
        </div>
    );
}


HeaderLogo.propTypes = {
    headerIsFixed: PropTypes.bool,
    isPortrait: PropTypes.bool,
    isLandscape: PropTypes.bool
};



export default HeaderLogo;