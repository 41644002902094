import PropTypes from 'prop-types';
import Link from "next/link";

import Style from 'components/applications/header_footer/Footer/styles/Footer.module.scss';


function ColumnItem({ href, title, text }){
    return (
        <li className={Style.item}>
            {href.includes("http") ? 
                <a 
                    href={href} 
                    target="_blank" 
                    rel="noreferrer" 
                    title={title} 
                    className={Style.listLink}
                >
                    {text}
                </a>
            :
                <Link href={href} className={Style.listLink} title={title}>
                    {text}
                </Link>
            }
        </li>
    );
}


ColumnItem.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string
};


export default ColumnItem;