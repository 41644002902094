

/**
 * Gets the Featured offers singleton from the cms
 * @returns {string} - GraphQL query string
 */

export default function GetFeaturedOffers(){
    return `query GetFeaturedOffers {
                entry(section: "featuredOffers") {
                    ... on featuredOffers_featuredOffers_Entry {
                        ctaUrl
                    }
                }
            }`
}