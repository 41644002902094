
export function IconLogoOcean(props){
    return(
        <svg xmlSpace="preserve" viewBox="0 0 559 89" {...props} aria-label="AuthenTEAK">
            <path fill="#2E303B" d="m8.9 34.9c1.2-2.4 2.8-4.4 4.8-6s4.4-2.7 7.2-3.4c2.7-0.7 5.8-1.1 9.1-1.1 2.5 0 5 0.2 7.6 0.7 2.5 0.5 4.8 1.4 6.8 2.7s3.6 3.2 4.9 5.6 1.9 5.5 1.9 9.4v30.5c0 2.8 1.4 4.2 4.1 4.2 0.8 0 1.6-0.2 2.2-0.5v6c-0.8 0.1-1.5 0.3-2.2 0.3-0.6 0.1-1.4 0.1-2.4 0.1-1.8 0-3.2-0.2-4.3-0.7s-1.9-1.2-2.5-2.1-1-1.9-1.2-3.2c-0.2-1.2-0.3-2.6-0.3-4.1h-0.2c-1.3 1.9-2.5 3.5-3.8 5-1.2 1.5-2.6 2.7-4.2 3.7s-3.4 1.7-5.4 2.2-4.5 0.8-7.3 0.8c-2.7 0-5.2-0.3-7.5-1-2.3-0.6-4.4-1.6-6.1-3s-3.1-3.1-4.1-5.2-1.5-4.6-1.5-7.5c0-4 0.9-7.1 2.7-9.4 1.8-2.2 4.1-4 7.1-5.2 2.9-1.2 6.2-2 9.9-2.5s7.4-0.9 11.2-1.4c1.5-0.1 2.8-0.3 3.9-0.6 1.1-0.2 2-0.6 2.8-1.2 0.7-0.6 1.3-1.3 1.7-2.3s0.6-2.2 0.6-3.8c0-2.4-0.4-4.3-1.2-5.8s-1.9-2.7-3.2-3.6c-1.4-0.9-3-1.5-4.8-1.8s-3.8-0.5-5.9-0.5c-4.5 0-8.1 1.1-10.9 3.2s-4.3 5.5-4.5 10.2h-7c0.1-3.3 0.8-6.2 2-8.7m35.1 17.2c-0.4 0.8-1.3 1.4-2.6 1.8s-2.4 0.6-3.3 0.8c-3 0.5-6 1-9.2 1.4s-6 1-8.6 1.8-4.7 2-6.4 3.5-2.5 3.7-2.5 6.5c0 1.8 0.3 3.4 1.1 4.7 0.7 1.4 1.7 2.6 2.8 3.6 1.2 1 2.6 1.8 4.1 2.3 1.6 0.5 3.2 0.8 4.8 0.8 2.7 0 5.2-0.4 7.7-1.2s4.6-2 6.4-3.6c1.8-1.5 3.3-3.4 4.3-5.7 1.1-2.2 1.6-4.7 1.6-7.6v-9.1h-0.2z"/>
            <path fill="#2E303B" d="m104.8 83.4v-10.3h-0.2c-1.9 3.9-4.5 6.8-8.1 8.8-3.5 2-7.4 3.1-11.7 3.1-3.6 0-6.7-0.5-9.3-1.5s-4.6-2.4-6.2-4.3-2.8-4.1-3.5-6.8-1.1-5.7-1.1-9.1v-37.3h7v37.4c0.2 5.2 1.4 9.1 3.6 11.8 2.3 2.7 6.2 4 11.7 4 3 0 5.6-0.6 7.8-1.9 2.1-1.3 3.9-3 5.3-5.2s2.4-4.6 3.1-7.4 1-5.6 1-8.5v-30.2h7v57.4h-6.4z"/>
            <path fill="#2E303B" d="m146.7 26v5.9h-11.7v38.7c0 2.3 0.3 4.1 0.9 5.4s2.2 2 4.7 2.2c2 0 4-0.1 6-0.4v5.9c-1 0-2.1 0-3.1 0.1s-2.1 0.1-3.1 0.1c-4.7 0-7.9-0.9-9.8-2.7-1.8-1.8-2.7-5.2-2.7-10.1v-39.3h-10v-5.8h10v-17.3h7v17.3h11.8z"/>
            <path fill="#2E303B" d="m163.1 4v31.9h0.2c1.3-3.5 3.7-6.3 7.1-8.4s7.1-3.2 11.2-3.2c4 0 7.4 0.5 10.1 1.6 2.7 1 4.9 2.5 6.5 4.4s2.8 4.2 3.4 6.9c0.7 2.7 1 5.8 1 9.2v36.9h-7v-35.7c0-2.4-0.2-4.7-0.7-6.8-0.4-2.1-1.2-3.9-2.3-5.5s-2.6-2.8-4.5-3.7-4.2-1.3-7.1-1.3c-2.8 0-5.3 0.5-7.5 1.5s-4 2.4-5.6 4.1c-1.5 1.7-2.7 3.8-3.6 6.2s-1.3 5-1.4 7.8v33.5h-7v-79.4h7.2z"/>
            <path fill="#2E303B" d="m218.4 64.4c0.7 2.7 1.8 5.1 3.4 7.3s3.5 4 5.9 5.3c2.4 1.4 5.2 2.1 8.6 2.1 5.1 0 9.1-1.3 12-4s4.9-6.2 6-10.7h7c-1.5 6.5-4.2 11.6-8.2 15.1-4 3.6-9.6 5.3-16.8 5.3-4.5 0-8.4-0.8-11.7-2.4s-6-3.8-8.1-6.6-3.6-6-4.6-9.7-1.5-7.5-1.5-11.6c0-3.8 0.5-7.5 1.5-11.1s2.5-6.9 4.6-9.7 4.8-5.1 8.1-6.9c3.3-1.7 7.2-2.6 11.7-2.6 4.6 0 8.5 0.9 11.8 2.8 3.3 1.8 5.9 4.3 7.9 7.3s3.5 6.5 4.4 10.3c0.9 3.9 1.3 7.8 1.1 11.8h-44.1c0 2.7 0.3 5.3 1 8m34.7-21.4c-0.9-2.4-2.1-4.6-3.6-6.5-1.6-1.9-3.4-3.4-5.7-4.5-2.2-1.2-4.7-1.7-7.6-1.7s-5.5 0.6-7.7 1.7c-2.2 1.2-4.1 2.7-5.7 4.5-1.6 1.9-2.8 4.1-3.7 6.6s-1.5 5-1.8 7.6h37.2c-0.1-2.7-0.5-5.2-1.4-7.7"/>
            <path fill="#2E303B" d="m277.3 26v9.9h0.2c1.3-3.5 3.7-6.3 7.1-8.4s7.1-3.2 11.2-3.2c4 0 7.3 0.5 10 1.6 2.7 1 4.9 2.5 6.5 4.4s2.8 4.2 3.4 6.9c0.7 2.7 1 5.8 1 9.2v36.9h-7v-35.7c0-2.4-0.2-4.7-0.7-6.8s-1.2-3.9-2.3-5.5-2.6-2.8-4.5-3.7-4.2-1.3-7.1-1.3c-2.8 0-5.3 0.5-7.5 1.5s-4 2.4-5.6 4.1c-1.5 1.7-2.7 3.8-3.5 6.2-0.9 2.4-1.3 5-1.4 7.8v33.5h-7v-57.4h7.2z"/>
            <polygon fill="#4C7B9A" points="373.9 24.8 373.9 29.6 351.3 29.6 351.3 83.4 345.1 83.4 345.1 29.6 322.5 29.6 322.5 24.8"/>
            <polygon fill="#4C7B9A" points="373.9 24.8 373.9 29.6 351.3 29.6 351.3 83.4 345.1 83.4 345.1 29.6 322.5 29.6 322.5 24.8"/>
            <polygon fill="#4C7B9A" points="428.1 24.8 428.1 29.6 389.4 29.6 389.4 50.7 425.7 50.7 425.7 55.5 389.4 55.5 389.4 78.6 428.6 78.6 428.6 83.4 383.3 83.4 383.3 24.8"/>
            <path fill="#4C7B9A" d="m466.3 24.8 25.5 58.6h-6.6l-7.9-18.2h-29.6l-7.8 18.2h-6.6l26.1-58.6h6.9zm8.8 35.6-12.6-30-13 30h25.6z"/>
            <path fill="#4C7B9A" d="m466.3 24.8 25.5 58.6h-6.6l-7.9-18.2h-29.6l-7.8 18.2h-6.6l26.1-58.6h6.9zm8.8 35.6-12.6-30-13 30h25.6z"/>
            <polygon fill="#4C7B9A" points="507.1 24.8 507.1 56.3 544.9 24.8 553.2 24.8 524.2 49 554.6 83.4 546.6 83.4 519.5 52.9 507.1 63.3 507.1 83.4 500.9 83.4 500.9 24.8"/>
            <polygon fill="#4C7B9A" points="507.1 24.8 507.1 56.3 544.9 24.8 553.2 24.8 524.2 49 554.6 83.4 546.6 83.4 519.5 52.9 507.1 63.3 507.1 83.4 500.9 83.4 500.9 24.8"/>
        </svg>
    )
}