import Badge from 'components/display/Badge';
import clsx from 'clsx';
import Link from 'next/link';
import { IconCart } from 'components/icons/IconCart';

// import Style from './MyCartBtn.module.scss';


function IconButton({ qty, isMobile, isImmobile, Style, id="" }){
    return (
        <Link 
            href={"/cart" + (id && `?id=${id}`)}
            className={clsx(Style.block, isImmobile && Style.isImmobile)}
            title="go to your cart"
        >

            {qty ? 
                <Badge content={qty} max={99} isInline={isMobile}>
                    <IconCart 
                        className={Style.icon} 
                        width="24" 
                        height="24" 
                    />
                </Badge>
            :
                <IconCart 
                    className={Style.icon} 
                    width="24" 
                    height="24" 
                />
            }
            <span className={Style.text}>
                Cart
            </span>

        </Link>
    );
}


export default IconButton;