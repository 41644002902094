"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import ChatStyle from "components/help/Chat/Chat.module.scss"
import Style from 'components/applications/header_footer/Header/styles/Header.module.scss';
import UtilityStyle from "../Header/styles/HeaderUtility.module.scss"
import AuxStyle from '../Header/components/AuxillaryLinks/AuxillaryLinks.module.scss';
import LogoStyle from "../Header/components/HeaderLogo/HeaderLogo.module.scss"
import MainNavStyle from "../Header/components/MainNav/MainNav.module.scss"
import MyCartStyle from "../Header/components/MyCartBtn/MyCartBtn.module.scss"
import MegaFlyoutStyle from "../Header/components/MegaFlyout/MegaFlyout.module.scss"
import HeaderSearchStyle from "components/search/HeaderSearch/HeaderSearch.module.scss"
import PencilBannerStyle from "components/ads/PencilBanner/PencilBanner.module.scss";
import MobileHeaderStyle from "../Header/styles/MobileHeader.module.scss"
import MenuBtnStyle from "../Header/components/MenuBtn/MenuBtn.module.scss"
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from "react";
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import { HeaderContext } from 'components/applications/header_footer/Header/contexts/HeaderContext';
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";





function Header({ isMobile = true, isLandscape, isPortrait, }){
    const pathName = usePathname()
    const [ hide, setHide ] = useState(false);
    const [ headerTop, setTop ] = useState(0);
    
    const updateTop = (value) => { 
        setTop(value) 
    };

    const [ showUnderlay, setOverlayShown ] = useState(false);
    const updateOverlay = (bool) => { 
        setOverlayShown(bool) 
    }

    const [flyoutShown, setFlyoutShown] = useState(null);


    useEffect(() => {
        switch ( pathName ) {
            case "/p/tearsheet/":
            case "/login/":
            case "/logout/":
                setHide(true)
            break;
        }

    }, [ pathName ])


    const header = useMemo(() => {
        if(isMobile){
            return(
                <MobileHeader 
                    Style={MobileHeaderStyle}
                    {...{ 
                        headerTop, 
                        showUnderlay, 
                        isLandscape, 
                        isPortrait,
                        AuxStyle,
                        LogoStyle,
                        ChatStyle,
                        MyCartStyle,
                        PencilBannerStyle,
                        UtilityStyle,
                        HeaderSearchStyle,
                        MainNavStyle,
                        MenuBtnStyle
                    }} 
                />
            )
        }else{
            return(
                <DesktopHeader 
                    MobileStyle={MobileHeaderStyle}
                    {...{ 
                        isMobile,
                        headerTop, 
                        showUnderlay, 
                        flyoutShown,
                        MainNavStyle,
                        AuxStyle,
                        LogoStyle,
                        MegaFlyoutStyle,
                        HeaderSearchStyle,
                        MenuBtnStyle,
                        ChatStyle,
                        MyCartStyle,
                        PencilBannerStyle,
                        UtilityStyle
                    }} 
                />
            )
        }
    }, [isMobile])



    return(   
        <HeaderContext.Provider value={{ headerTop, updateTop, showUnderlay, updateOverlay, flyoutShown, setFlyoutShown }}>
            <header className={clsx(Style.block, hide && Style.hide )}>
                {header}
            </header>
        </HeaderContext.Provider>
    )
}


Header.propTypes = {
    isLandscape: PropTypes.bool,
    isPortrait: PropTypes.bool
};



export default Header;