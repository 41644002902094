
/**
 * Get the header Pencil Banner
 */

export default function GetPencilBanner(){
    return `query getPencilBanner {
                entries(section: "pencilBanners", limit: 1) {
                    ... on pencilBanners_pencilBanners_Entry {
                        id
                        href
                        body
                        backgroundColor {
                            label
                            class
                        }
                        textColor {
                            label
                            class
                        }
                    }
                }
            }`
}