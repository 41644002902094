import { useEffect, useState } from "react";


export default function useImmobility({ entry, pathname }){
    const [ hasImmobility, setHasImmobility ] = useState(true)

    useEffect(() => {
        if( pathname ){
            if( 
                pathname !== "/[slug]" && 
                pathname !== "/swatches" &&
                pathname !== "/" &&
                !pathname.includes("/outdoor-furniture") &&
                !pathname.includes("/grills-outdoor-kitchens") &&
                !pathname.includes("/maintenance-care") &&
                !pathname.includes("/outdoor-decor") &&
                !pathname.includes("/featured-offers") &&
                !pathname.includes("/shop-all-brands") &&
                !pathname.includes("/outdoor-entertainment") &&
                !pathname.includes("/outdoor-furniture") &&
                !pathname.includes("/outdoor-heating") &&
                !pathname.includes("/patio-umbrellas-accessories") &&
                !pathname.includes("/accessories") &&
                !pathname.includes("/planters") &&
                !pathname.includes("/help")
            ){
                setHasImmobility(entry.isIntersecting);
            }
        }
        

    }, [ entry.isIntersecting, pathname ])


    return hasImmobility
}