import PropTypes from 'prop-types';
import Loader from 'components/feedback/Loader';
import Style from './Backdrop.module.scss';
import clsx from 'clsx';
import { useCallback } from 'react';

function Backdrop({ 
    show, 
    headerHeight, 
    showLoader, 
    updateOverlay = () => false, 
    isImmobile, 
    isTakeover ,
    onClick = () => null
}){

    const closeOverlay = useCallback(() => {
        updateOverlay(false)
        onClick()

    }, [ show ])


    return(
        <>
        {show && (
            <div 
                role="presentation"
                className={clsx(Style.underlay, isTakeover && Style.takeover)} 
                style={{top: !isImmobile ? 0 : headerHeight}} 
                onClick={closeOverlay}
            >
                {showLoader && <Loader isLoading isLarge />}
            </div>
        )}
        </>
    )
}

Backdrop.propTypes = {
    isImmobile: PropTypes.bool,
    show: PropTypes.bool,
    showLoader: PropTypes.bool,
    updateOverlay: PropTypes.func,
    headerHeight: PropTypes.number,
    isTakeover: PropTypes.bool
};


export default Backdrop;