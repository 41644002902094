/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import Link from 'next/link';
import { IconMyAccount } from 'components/icons/IconMyAccount';
import Button from 'components/forms/Button';
import useDropDown from 'components/applications/header_footer/Header/hooks/useDropDown'
import { parseCookies } from 'nookies'
import { useCart } from 'components/applications/cart/hooks/useCart';
import { useContext, useEffect } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { signIn, signOut } from 'next-auth/react';
import utils from 'styles/globals/utils.module.scss'
import { usePathname, useRouter } from 'next/navigation';
import { MyAccountFlyout } from 'lib/Constants';

// import Style from 'components/applications/header_footer/Header/styles/HeaderUtility.module.scss';


function MyAccountBtn({ hideFlyout, isHandheld, Style }){
    const asPath = usePathname()
    const dropDown = useDropDown({ hideFlyout, isHandheld })
    const { itemMutation } = useCart()
    const [global, globalDispatch] = useContext(GlobalContext);
  	const router = useRouter()



    useEffect(() => {
        const cookies = parseCookies();
        const userData = cookies.TEAK_user;

        if( userData && !global.user.isLoggedIn ){
            globalDispatch({
                type: "login",
                data: JSON.parse(userData)
            })
        }

    }, [])

    const Logout = () => {
        router.push("/logout");
    }

    
    return (
        <ul className={Style.buttonCntr}>
            <li className={Style.buttonItem} onPointerOver={dropDown.show} onPointerLeave={dropDown.clear}>
                <Link
                    href={global.user.isLoggedIn ? "/account" : "/login/?return=/account"}
                    title="Your account"
                    onClick={dropDown.show}
                    className={Style.button}
                    type="button">

                    <IconMyAccount 
                        className={Style.icon} 
                        width="24" 
                        height="24" 
                    />

                    {global.user.isLoggedIn ? 
                        <span className={Style.text}>
                            Hi, {global.user.first_name}
                        </span>
                    :
                        <span className={Style.text}>
                            Account
                        </span>
                    }

                </Link>
                

                {(dropDown.isShown && Array.isArray(MyAccountFlyout)) && 
                    <ul className={Style.utilMenu}>
                        {MyAccountFlyout.map((item) => {
                            return (
                                <li className={Style.utilMenuItem} key={item.name}>
                                    <Link href={item.url} title={item.title} className={Style.utilMenuLink}>
                                        {item.name}
                                    </Link>
                                </li>
                            );
                        })}
                        
                        <li className={Style.utilMenuItem}>
                            {global.user.isLoggedIn ? (
                                <Button 
                                    onClick={Logout}
                                    className={utils.m_1} 
                                    type="button" 
                                    title="logout" 
                                    size="full" 
                                    color="dark" 
                                    variant="outline"
                                >
                                    Logout
                                </Button>
                            ):(
                                <>
                                    <Button 
                                        className={utils.m_1} 
                                        type="button" 
                                        onClick={() => signIn({ callbackUrl: asPath }) }
                                        title="login to your account" 
                                        color="indigo" 
                                        size="full"
                                    >
                                        Sign In
                                    </Button>

                                    <Button 
                                        className={utils.m_1}  
                                        type="text" 
                                        onClick={() => signIn({ callbackUrl: asPath }) }
                                        title="create an account" 
                                        color="indigo" 
                                        size="full" 
                                        variant="outline"
                                    >
                                        Register
                                    </Button>
                                </>
                            )}                       
                        </li>
                    </ul>
                }
            </li>
        </ul>
    );
}


MyAccountBtn.propTypes = {
    hideFlyout: PropTypes.bool,
    headerIsImmobile: PropTypes.bool,
    isLandscape: PropTypes.bool
};


export default MyAccountBtn;