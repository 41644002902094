

export function GetFlyout(slug) {
  return` 
    query GetFlyout {
      entries(section: "flyouts", slug: "${slug}") {
        ... on flyouts_default_Entry {

          flyoutBuilder {
            ... on flyoutBuilder_column_BlockType {
              headingTitle
              headingHref
              headingImage {
                url
                filename
                width
                height
              }
              typeHandle
              id
              kids {
                ... on kids_BlockType {
                  id
                  kidHref
                  kidTitle
                  kidDisplay
                  kidImg {
                    url
                    filename
                    width
                    height
                  }
                }
              }
            }

            ... on flyoutBuilder_featured_BlockType {
              typeHandle
              features {
                ... on features_BlockType {
                  featureTitle
                  featureHref
                  id
                  featureImage {
                    url
                    filename
                    width
                    height
                  }
                }
              }
            }

            ... on flyoutBuilder_blogs_BlockType {
              typeHandle
              blogPosts {
                ... on blogPosts_BlockType {
                  id
                  blogTitle
                  blogHref
                }
              }
              blogImage {
                url
                filename
                width
                height
              }
            }

            
          }
        }
      }
    }
  `   
}