import { parseCookies } from 'nookies'
import { useEffect, useMemo, useRef, useState } from 'react'
import { usePathname } from 'next/navigation'
import { LocalStorage, storageKeys } from 'services/LocalStorage.service'
import { getCMSRejoinerRules } from './Rejoiner.service'
import { setRejoinerDismissedCookie, validatePaths } from './Rejoiner.helpers'
import { cloudinaryCmsImageLoader } from 'thirdparty/Cloudinary/cloudinaryLoader'



export default function useRejoiner(){
    const rejoinerModalTimeout = useRef()
    const pathName = usePathname()
    const [ rules, setRules ] = useState(null)
    const [ openModal, setOpenModal ] = useState(false)

    useEffect(() => {
        // session storage will cause a fetch fresh for every user session.  
        // TODO: Can (should?) convert to local storage and add some sort of time to live value
        const storedRules = LocalStorage.getStorage(storageKeys.rejoiner.rules)

        if( storedRules ){
            setRules(storedRules)

        }else{
            getCMSRejoinerRules().then(response => {
                LocalStorage.setStorage(storageKeys.rejoiner.rules, response)
                setRules(response)
            })
        }

    }, [])


    const images = useMemo(() => {
        return {
            desktop: {
                rule: rules?.image?.[0]?.filename ? cloudinaryCmsImageLoader({ 
                    src: rules.image?.[0]?.filename ?? "",
                    width: 400,
                    quality: 80 
                }) : null,
                fallback: "https://res.cloudinary.com/authenteak/image/upload/w_400,q_80/f_auto/cms/rejoiner-image-desktop.webp"
            },
            mobile: {
                rule: rules?.mobileImage?.[0]?.filename ? cloudinaryCmsImageLoader({ 
                    src: rules.mobileImage?.[0]?.filename ?? "",
                    width: 400,
                    quality: 80 
                }) : null,
                fallback: "https://res.cloudinary.com/authenteak/image/upload/w_400,q_80/f_auto/cms/rejoiner-image-mobile.webp"
            }
        }


    }, [ 
        rules?.image?.[0]?.url,
        rules?.mobileImage?.[0]?.url
    ])


    
    useEffect(() => {
        // check to see if the current path matches those set in the rules
        const inValidPath = validatePaths(pathName, rules?.doNotShow)

        // check if we have cookies set to not show this modal
        const cookieStore = parseCookies()

        const hasDismissedCookie = cookieStore?.TEAK_rejoinerEmailToaster
        const hasRejoinerProfile = cookieStore?.TEAK_rejoinerCustomerProfile

        if( !hasDismissedCookie && !hasRejoinerProfile && !inValidPath ){
            const delay = (rules?.delay ?? 10) * 1000;

            // show in 10 seconds
            rejoinerModalTimeout.current = setTimeout(() => {
                setOpenModal(true)
            }, delay)

        }else if( inValidPath ){
            clearTimeout(rejoinerModalTimeout.current)
        }

    }, [ pathName, rules ])




    function closeModal(){
        setOpenModal(false)
        setRejoinerDismissedCookie(rules)

        clearTimeout(rejoinerModalTimeout.current)
    }



    return {
        rules,
        images,
        openModal,
        closeModal
    };
}