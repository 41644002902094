import { createContext } from 'react';


/**
 * Static Context Provider
 * - store state variables, not api data
 */

const HeaderContext = createContext({});
const HeaderDropdownContext = createContext({});


export {
    HeaderContext,
    HeaderDropdownContext,
}