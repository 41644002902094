import { useEffect, useState } from "react"


export default function useWrapperBlur(){
    const [ shouldBlur, setShouldBlur ] = useState(false)

    useEffect(() => {
        if(document.getElementById("wrapper")){
            document.getElementById("wrapper").style.filter = shouldBlur ? `blur(5px)` : `none`
            document.getElementsByTagName("body")[0].style.overflow = shouldBlur ? "hidden" : ""
        }    

    }, [ shouldBlur ])

    
    const blur = () => setShouldBlur(true)
    const unBlur = () => setShouldBlur(false)

    return{
        blur,
        unBlur
    }
}