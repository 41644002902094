
export function IconLogo20Yrs(props){
    return(
        <svg xmlSpace="preserve" viewBox="0 0 878 212" {...props} aria-label="AuthenTEAK 20th Year">
            <g id="Artboard_1" data-name="Artboard – 1" clipPath="url(#clip-Artboard_1)">
                <rect width="878" height="212" fill="#fff"/>
                <g id="Group_1059" data-name="Group 1059" transform="translate(535.607 -1383.975)">
                <g id="Group_651" data-name="Group 651" transform="translate(-676 1379)">
                    <g id="Group_1054" data-name="Group 1054" transform="translate(179 62)">
                    <path id="Path_774" data-name="Path 774" d="M104.7,31.752a19.147,19.147,0,0,1,5.189-6.487,19.988,19.988,0,0,1,7.784-3.676,39.565,39.565,0,0,1,9.838-1.189,43.042,43.042,0,0,1,8.216.757,20.968,20.968,0,0,1,7.351,2.919,16.181,16.181,0,0,1,5.3,6.054c1.405,2.595,2.054,5.946,2.054,10.162V73.266c0,3.027,1.514,4.541,4.432,4.541a5.5,5.5,0,0,0,2.378-.541v6.487a20.748,20.748,0,0,1-2.378.324,17.59,17.59,0,0,1-2.595.108,11.288,11.288,0,0,1-4.649-.757,6.241,6.241,0,0,1-2.7-2.27,8.257,8.257,0,0,1-1.3-3.46,27.484,27.484,0,0,1-.324-4.433h-.216a58.69,58.69,0,0,1-4.108,5.406,17.781,17.781,0,0,1-4.541,4,20.07,20.07,0,0,1-5.838,2.378,33.2,33.2,0,0,1-7.892.865,27.755,27.755,0,0,1-8.108-1.081A17.138,17.138,0,0,1,106,81.59a16.27,16.27,0,0,1-4.433-5.622,18.7,18.7,0,0,1-1.622-8.108c0-4.324.973-7.676,2.919-10.162a18.7,18.7,0,0,1,7.676-5.622,43.054,43.054,0,0,1,10.7-2.7c4-.541,8-.973,12.108-1.514a21.653,21.653,0,0,0,4.216-.649,6.976,6.976,0,0,0,3.027-1.3,6.242,6.242,0,0,0,1.838-2.487,10.9,10.9,0,0,0,.649-4.108,13.181,13.181,0,0,0-1.3-6.27,10.978,10.978,0,0,0-3.46-3.892,13.621,13.621,0,0,0-5.189-1.946,38.983,38.983,0,0,0-6.378-.541c-4.865,0-8.757,1.189-11.784,3.46s-4.649,5.946-4.865,11.027h-7.568a23.159,23.159,0,0,1,2.162-9.406m37.947,18.595a4.4,4.4,0,0,1-2.811,1.946c-1.405.432-2.595.649-3.568.865-3.243.541-6.487,1.081-9.946,1.514a54.086,54.086,0,0,0-9.3,1.946,18.5,18.5,0,0,0-6.919,3.784c-1.838,1.622-2.7,4-2.7,7.027a9.564,9.564,0,0,0,1.189,5.081,13.465,13.465,0,0,0,3.027,3.892,13.782,13.782,0,0,0,4.432,2.487,17.4,17.4,0,0,0,5.189.865,26.814,26.814,0,0,0,8.324-1.3,18.878,18.878,0,0,0,6.919-3.892,16.62,16.62,0,0,0,4.649-6.162,18.022,18.022,0,0,0,1.73-8.216V50.346h-.216" transform="translate(106.13 12.035)" fill="#2e303b"/>
                    <path id="Path_775" data-name="Path 775" d="M203.5,84.055V72.92h-.216a20.825,20.825,0,0,1-8.757,9.514,25.118,25.118,0,0,1-12.649,3.351,28.006,28.006,0,0,1-10.054-1.622,15.918,15.918,0,0,1-6.7-4.649,18.292,18.292,0,0,1-3.784-7.351,38.932,38.932,0,0,1-1.189-9.838V22h7.568V62.433c.216,5.622,1.513,9.838,3.892,12.757,2.486,2.919,6.7,4.324,12.649,4.324a16.256,16.256,0,0,0,8.433-2.054,17.767,17.767,0,0,0,5.73-5.622,26.6,26.6,0,0,0,3.351-8,37.485,37.485,0,0,0,1.081-9.189V22h7.568V84.055Z" transform="translate(111.013 12.164)" fill="#2e303b"/>
                    <path id="Path_776" data-name="Path 776" d="M244.479,23.4v6.378H231.83V71.62a14.228,14.228,0,0,0,.973,5.838c.649,1.405,2.378,2.162,5.081,2.378a43.6,43.6,0,0,0,6.487-.432v6.378c-1.081,0-2.27,0-3.351.108s-2.27.108-3.351.108c-5.081,0-8.541-.973-10.595-2.919-1.946-1.946-2.919-5.622-2.919-10.919V29.673H213.343V23.4h10.811V4.7h7.568V23.4Z" transform="translate(115.327 10.761)" fill="#2e303b"/>
                    <path id="Path_777" data-name="Path 777" d="M259.127,0V34.487h.216a17.887,17.887,0,0,1,7.676-9.081,22.557,22.557,0,0,1,12.108-3.46,29.286,29.286,0,0,1,10.919,1.73,16.555,16.555,0,0,1,7.027,4.757,17.291,17.291,0,0,1,3.676,7.46,40.056,40.056,0,0,1,1.081,9.946V85.731h-7.568v-38.6a31.97,31.97,0,0,0-.757-7.351,15.432,15.432,0,0,0-2.487-5.946,11.869,11.869,0,0,0-4.865-4,17.714,17.714,0,0,0-7.676-1.405,19.391,19.391,0,0,0-8.108,1.622,19.069,19.069,0,0,0-6.054,4.433,21.438,21.438,0,0,0-3.892,6.7,26.532,26.532,0,0,0-1.514,8.433V85.839h-7.568V0Z" transform="translate(118.409 10.38)" fill="#2e303b"/>
                    <path id="Path_778" data-name="Path 778" d="M314.492,63.66a22.964,22.964,0,0,0,3.676,7.892,19.068,19.068,0,0,0,6.378,5.73,17.955,17.955,0,0,0,9.3,2.27c5.514,0,9.838-1.405,12.973-4.324A22.592,22.592,0,0,0,353.3,63.66h7.568c-1.622,7.027-4.541,12.541-8.865,16.325-4.324,3.892-10.379,5.73-18.162,5.73a28.712,28.712,0,0,1-12.649-2.595,23.641,23.641,0,0,1-8.757-7.135A30.367,30.367,0,0,1,307.465,65.5a47.467,47.467,0,0,1-1.622-12.541,44.629,44.629,0,0,1,1.622-12,31.331,31.331,0,0,1,4.973-10.487,26.807,26.807,0,0,1,8.757-7.46A27.287,27.287,0,0,1,333.844,20.2,25.04,25.04,0,0,1,346.6,23.227a24,24,0,0,1,8.541,7.892A34.35,34.35,0,0,1,359.9,42.254a46.04,46.04,0,0,1,1.189,12.757H313.411a33.861,33.861,0,0,0,1.081,8.649m37.514-23.136a26.086,26.086,0,0,0-3.892-7.027,18.116,18.116,0,0,0-6.162-4.865,16.751,16.751,0,0,0-8.216-1.838,18.508,18.508,0,0,0-8.325,1.838,22.721,22.721,0,0,0-6.162,4.865,23,23,0,0,0-4,7.135,36.346,36.346,0,0,0-1.946,8.216h40.217a26.985,26.985,0,0,0-1.513-8.324" transform="translate(122.829 12.019)" fill="#2e303b"/>
                    <path id="Path_779" data-name="Path 779" d="M373.327,22.138v10.7h.216a17.887,17.887,0,0,1,7.676-9.081,22.557,22.557,0,0,1,12.108-3.46,28.688,28.688,0,0,1,10.811,1.73,16.554,16.554,0,0,1,7.027,4.757,17.29,17.29,0,0,1,3.676,7.46,40.056,40.056,0,0,1,1.081,9.946V84.085h-7.568V45.49a31.97,31.97,0,0,0-.757-7.351,17.294,17.294,0,0,0-2.486-5.946,11.87,11.87,0,0,0-4.865-4,17.713,17.713,0,0,0-7.676-1.405,19.39,19.39,0,0,0-8.108,1.622,19.07,19.07,0,0,0-6.054,4.433,19.333,19.333,0,0,0-3.784,6.7,26.538,26.538,0,0,0-1.514,8.433V84.193h-7.568V22.138Z" transform="translate(127.67 12.026)" fill="#2e303b"/>
                    <path id="Path_781" data-name="Path 781" d="M473.512,20.8v5.189H449.079V84.152h-6.7V25.989H417.943V20.8Z" transform="translate(131.92 12.067)" fill="#4c7b9a"/>
                    <path id="Path_782" data-name="Path 782" d="M527.176,20.8v5.189H485.338V48.8h39.244V53.99H485.338V78.963h42.379v5.189H478.743V20.8Z" transform="translate(136.851 12.067)" fill="#4c7b9a"/>
                    <path id="Path_784" data-name="Path 784" d="M564.419,20.8l27.568,63.352h-7.135l-8.541-19.676h-32l-8.433,19.676h-7.135L556.96,20.8Zm9.514,38.487L560.311,26.854,546.257,59.287Z" transform="translate(140.906 12.067)" fill="#4c7b9a"/>
                    <path id="Path_786" data-name="Path 786" d="M603.046,20.8V54.855L643.912,20.8h8.973L621.533,46.963,654.4,84.152H645.75l-29.3-32.974L603.046,62.422v21.73h-6.7V20.8Z" transform="translate(146.388 12.067)" fill="#4c7b9a"/>
                    <g id="Group_1053" data-name="Group 1053">
                        <path id="Path_788" data-name="Path 788" d="M65.157,73.349c-11.287,11.5-24.616,23.529-32.911,30.8H63.8c9.247,0,14.28-4.5,15.5-14.145h1.5v23.924H18.646v-1.849c35.768-28.553,47.735-46.265,47.735-68.87,0-15.863-5.847-23.529-17.269-23.529-9.25,0-15.914,4.231-15.914,11.236,0,2.511,1.769,5.024,1.769,7.666,0,3.04-2.585,4.495-5.44,4.495-3.809,0-6.936-1.586-6.936-6.742,0-9.647,12.1-18.5,26.522-18.5,19.31,0,30.053,11.1,30.053,26.7,0,11.236-6.664,21.414-14.007,28.817" transform="translate(-18.646 -17.831)" fill="#4c7b9a"/>
                        <path id="Path_789" data-name="Path 789" d="M74.74,115.781c-20.809,0-31.824-18.242-31.824-49.042,0-30.666,11.015-48.908,31.824-48.908,20.944,0,31.824,18.508,31.824,48.908,0,30.8-11.018,49.042-31.824,49.042M56.517,70.838c0,19.3,1.9,27.76,3.944,33.18,2.448,6.476,7.209,9.914,14.145,9.914,12.781,0,18.358-8.594,18.358-46.268,0-20.223-1.362-31.854-3.944-37.934-2.858-6.742-6.8-10.048-14.414-10.048-6.8,0-11.563,3.307-14.01,9.65-3.537,9.121-4.079,21.019-4.079,41.507" transform="translate(29.71 -17.831)" fill="#4c7b9a"/>
                    </g>
                    <path id="Path_796" data-name="Path 796" d="M12.365,12.958v-8.5L20.306-7.813H15.261L10.154.533,4.985-7.813H-.06l8,12.269v8.5Zm24.57,0V9.066H26.659v-4.7H36.717V.471H26.659V-3.92H36.935V-7.813h-14.7V12.958Zm22.826,0-8-20.771H46.215L38.181,12.958h5.045l1.308-3.519H53.44l1.277,3.519ZM52.225,5.547H45.748l3.239-8.937Zm26.283,7.411-4.671-7.91a5.961,5.961,0,0,0,4.547-6.166c0-3.861-2.647-6.7-6.976-6.7H61.692V12.958h4.422V5.578h3.239l4.079,7.38ZM70.754,1.685h-4.64V-3.92h4.64a2.82,2.82,0,0,1,3.114,2.8A2.82,2.82,0,0,1,70.754,1.685ZM89.1,13.332c5.605,0,8.315-2.865,8.315-6.664,0-7.754-11.927-5.543-11.927-8.844,0-1.246,1.059-2.086,2.958-2.086a8.922,8.922,0,0,1,6.041,2.273l2.46-3.239a11.554,11.554,0,0,0-8.1-2.9c-4.889,0-7.879,2.865-7.879,6.322,0,7.816,11.958,5.294,11.958,8.969,0,1.183-1.183,2.3-3.612,2.3a9.107,9.107,0,0,1-6.6-2.8l-2.4,3.363A11.84,11.84,0,0,0,89.1,13.332Zm-77.1,31.141A10.436,10.436,0,0,0,22.86,33.729,10.436,10.436,0,0,0,11.991,22.986,10.429,10.429,0,0,0,1.154,33.729,10.429,10.429,0,0,0,11.991,44.473Zm0-3.924c-3.83,0-6.29-2.958-6.29-6.82,0-3.893,2.46-6.82,6.29-6.82s6.322,2.927,6.322,6.82C18.313,37.591,15.822,40.549,11.991,40.549ZM30.4,44.1V35.5H40.454V31.612H30.4V27.221H40.672V23.328h-14.7V44.1Z" transform="translate(151.687 8.124)" fill="#4c7b9a"/>
                    </g>
                </g>
                </g>
            </g>      
        </svg>
    )
}