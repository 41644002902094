"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import ColumnHeading from '../ColumnHeading';
import ColumnItem from '../ColumnItem';
import Style from 'components/applications/header_footer/Footer/styles/Footer.module.scss';


function NavElement({ isMobile, name, items, title }){    
    return(
        <details className={Style.list} open={!isMobile}>
            <ColumnHeading 
                title={title}
                name={name}
                isMobile={isMobile}
            />
 
            <ul className={Style.itemCntr}>
                {items.map((item) => {
                    return(
                        <ColumnItem 
                            {...item} 
                            key={item.text} 
                        />
                    )
                })}
            </ul>
        </details>
    )
}

NavElement.propTypes = {
    itemToggled: PropTypes.object,
    isMobile: PropTypes.bool,
    toggleItems: PropTypes.func
};

export default NavElement;