'use client'

import Modal from 'components/feedback/Modal'
import Style from './RejoinerEmailToaster.module.scss'
import { IconClose } from 'components/icons/IconClose'
import { IconLogoOcean } from 'components/icons/IconLogoOcean'
import SubmissionForm from './SubmissionForm'
import useRejoiner from 'thirdparty/Rejoiner/useRejoiner'
import Confirmation from './Confirmation'
import { useState } from 'react'

function RejoinerEmailToaster(){
    const [ success, setSuccess ] = useState(false)
    const rejoiner = useRejoiner()

    return(
        <>
            {/* <style type='text/css'>
                background-image: url("${rejoiner?.images?.desktop?.rule ?? rejoiner?.images?.desktop?.fallback}")
                background-image: url("${rejoiner?.images?.mobile?.rule ?? rejoiner?.images?.mobile?.fallback}")
            </style> */}

            <Modal
                shouldOpen={rejoiner.openModal}
                onClose={() => rejoiner.closeModal()}
                className={Style.modal}
                size='md'
                showHeader={false}
                willClose={() => rejoiner.closeModal()}
            >
                <button type="button" className={Style.closeBtn} onClick={() => rejoiner.closeModal()}>
                    <IconClose width={30} height={30} />
                </button>

                <div className={Style.block}>
                    <div className={Style.content}>
                        <IconLogoOcean className={Style.logo} />

                        {success ? (
                            <Confirmation {...{ rejoiner }} />
                            
                        ):(
                            <SubmissionForm 
                                onSuccess={() => setSuccess(true)} 
                            />
                        )}
                    </div>
{/* 
                    <Picture 
                        desktop={rejoiner?.images?.desktop?.rule ?? rejoiner?.images?.desktop?.fallback}
                        mobile={rejoiner?.images?.mobile?.rule ?? rejoiner?.images?.mobile?.fallback}
                        alt="Sign up now"
                        className={Style.image}
                        width={400}
                        height={400}
                        priority
                    /> */}

                </div>
            </Modal>
        </>
    )
}

export default RejoinerEmailToaster
