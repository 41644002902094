"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { memo, useContext, useEffect, useState } from 'react';
import { mainNavItems } from 'lib/Constants';
import { HeaderContext } from 'components/applications/header_footer/Header/contexts/HeaderContext';
import dynamic from 'next/dynamic';
import NavLink from './NavLink';
import clsx from 'clsx';
import { usePathname, useSearchParams } from 'next/navigation';
import Link from 'next/link';
import { GetFeaturedOffer } from "services/header/HeaderContext.service";


const MegaFlyout = dynamic(() => import('components/applications/header_footer/Header/components/MegaFlyout'))
const NavButton = dynamic(() => import('./NavButton'))
// const NavOffers = dynamic(() => import('./NavOffers'))


function MainNav({ 
    flyout = false, 
    isMobile, 
    isDesktop, 
    isTrade = false, 
    isIntersecting, 
    Style, 
    MegaFlyoutStyle 
}){
    const [ offerHref, setOfferHref ] = useState(null)

    useEffect(() => {
        GetFeaturedOffer().then(data => {
            setOfferHref(data)
        })

    }, [])


    const headerCtx = useContext(HeaderContext)
    const [ canClick, setCanClick ] = useState(false)

    const defaultFlyout = {
        type: null,
        height: "short",
        id: null
    }

    const [ flyoutData, setFlyoutData ] = useState(defaultFlyout)

    const searchParams = useSearchParams()
    const queryType = searchParams.get("type")
    const queryFlyout = searchParams.get("flyout")

    const asPath = usePathname()

    let timeout = null;

    useEffect(() => {
        clearTimeout(timeout);
        headerCtx.setFlyoutShown(null) 

        /* 
            300ms throttle of hover for when the component remounts
            on page load so the flyout isn't show again
            
            Chrome refires the onPointerEnter event when the component 
            remounts and the users mouse is still in the same place
        */ 
        setTimeout(() => {
            setCanClick(true)

        }, 500)


        if( queryType ){
            headerCtx.setFlyoutShown(queryType)
        }

        
        return() => {
            clearTimeout(timeout);
            setCanClick(false)
        }

    },[ asPath ])



    // clear and reset the flyout when the user scrolls the page
    useEffect(() => {        
        if( !isIntersecting ){
            clearTimeout(timeout);
            headerCtx.setFlyoutShown(null) 
            setFlyoutData(defaultFlyout)
        }

    }, [ isIntersecting])


    
    // clean up and clear open flyouts
    const clearFlyout = () => {
        if( queryFlyout ){ return }

        if( isDesktop ){
            clearTimeout(timeout);

            timeout = setTimeout(() => {  
                headerCtx.updateOverlay(false)
                headerCtx.setFlyoutShown(null) 
                setFlyoutData(defaultFlyout)

            }, 300)
        }
    }


    // display required flyout
    const showFlyout = (showFlyout) => {
        if( isDesktop && canClick ){
            clearTimeout(timeout);

            timeout = setTimeout(() => { 
                setFlyoutData({...showFlyout})
                headerCtx.updateOverlay(true)
                headerCtx.setFlyoutShown(showFlyout.type) 

            }, 550)
        }
    }

    
    // if open close flyout 
    const toggleFlyout = (showFlyout) => {
        if( isMobile && showFlyout !== headerCtx.flyoutShown ){
            
            setFlyoutData(flyoutData.id === showFlyout.id ? defaultFlyout : {...showFlyout})
            headerCtx.setFlyoutShown(flyoutData.type === showFlyout.type ? null : showFlyout.type) 

        }else if( isMobile ){
            headerCtx.setFlyoutShown(null)
            setFlyoutData(defaultFlyout)
        }
    }

    

    // clean up open flyout on page transitions
    useEffect(() => {
        headerCtx.setFlyoutShown(null) 
        headerCtx.updateOverlay(false)
        setFlyoutData(defaultFlyout)

    }, [ asPath ])


    const mainNav = {
        justifyContent: "space-between",
        position: "relative",
        margin: "0 auto",
        padding: "0 4vw",
        listStyle: "none",
        display: "flex",
        width: "100vw",
    }


    return(
        <>
            <nav className={clsx(flyout ? Style.mainFlyout : Style.mainNav)} onPointerLeave={clearFlyout}>
                <ul 
                    style={!flyout ? mainNav : {}} 
                    className={clsx(flyout ? Style.listFlyout : Style.list)} 
                >
                    {Array.isArray(mainNavItems) && mainNavItems.map(item => {
                        return(
                            <li 
                                key={item.id}
                                className={clsx(flyout && Style.itemFlyout, Style.megaItem)} 
                                onPointerEnter={() => showFlyout(item)} 
                            >
                                {isMobile ? (
                                    <>
                                        <NavButton 
                                            {...{ item, flyoutShown: headerCtx.flyoutShown, flyout }} 
                                            toggleFlyout={toggleFlyout}
                                        />

                                        {item.type === headerCtx.flyoutShown && (
                                            <div>   
                                                <MegaFlyout 
                                                    inMobileFlyout={flyout} 
                                                    {...flyoutData}
                                                    Style={MegaFlyoutStyle}
                                                />                                      
                                            </div> 
                                        )}
                                    </>

                                ):(

                                    <NavLink 
                                        {...{ item, flyout }} 
                                        clearFlyout={clearFlyout}
                                        activeMenu={flyoutData}
                                    />
                                )}
                            </li>
                        )
                    })}

                    
                    {!isTrade && <li className={Style.item} onPointerEnter={clearFlyout}>
                        <Link
                            href={offerHref?.data?.ctaUrl ?? "/featured-offers"}
                            title="Shop All Featured Offers"
                            className={clsx(Style.offers, flyout ? Style.linkFlyout : Style.link)}
                        >
                            Offers
                        </Link>
                    </li>}
                </ul>


                {!isMobile && (
                    <div>   
                        <MegaFlyout 
                            inMobileFlyout={flyout} 
                            {...flyoutData}
                        />                                      
                    </div> 
                )}

            </nav>
        </>
    )
}

MainNav.propTypes = {
    flyout: PropTypes.bool
};



export default memo(MainNav);