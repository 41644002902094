import PropTypes from 'prop-types';
import Style from 'components/applications/header_footer/Footer/styles/Footer.module.scss';
import clsx from 'clsx';

function ColumnHeading({ isMobile, title }){

    return(
        <summary className={clsx(Style.item, isMobile && Style.itemHeadingParent)}>
            <h5 className={Style.listHeading}>
                {title}
            </h5>
        </summary>
    )
}

ColumnHeading.propTypes = {
    isMobile: PropTypes.bool,
    title: PropTypes.string,
    name: PropTypes.string
};

export default ColumnHeading;