import Link from "next/link";
import Style from './MainNav.module.scss';
import clsx from "clsx";


function NavLink({ item, flyout, clearFlyout, activeMenu }){
    return (
        <Link  
            href={item.href}
            title={`Shop ${item.seoName}`}
            onClick={clearFlyout}
            className={clsx(flyout ? Style.linkFlyout : Style.link, activeMenu.href === item.href && Style.activeMainItem)}
        >
            {item.name} 
        </Link>
    );
}


export default NavLink;