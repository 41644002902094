import { useEffect } from 'react'
import Style from './RejoinerEmailToaster.module.scss'
import Button from 'components/forms/Button'
import utils from 'styles/globals/utils.module.scss';
import clsx from 'clsx';


function Confirmation({ rejoiner }){
    function closeModal(){
        rejoiner.closeModal()
    }

    useEffect(() => {
        setTimeout(() => {
            closeModal()
        }, 2000)
        
    }, [])


    return(
        <>
            <p className={clsx(Style.callOut, utils.mt_2, utils.mb_0)}>
                You're<br/>A VIP!
            </p>

            <p className={clsx(Style.text, utils.mb_2)}>
                You're entered to win a $250 gift card, and you'll receive email updates on exclusive savings and new savings.
            </p>

            <Button 
                type="button" 
                color="ocean"
                size="full"
                className={Style.submitBtn}
                onClick={closeModal}
            >
                Start Shopping
            </Button>
        </>
    )
}

export default Confirmation