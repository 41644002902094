
export default function GetNav(handle) {
	return `query getNav {
                navigationNodes(navHandle: "${handle}", level: 1) {
                    title
                    url
                    id
                    navHandle
                    children {
                        title
                        url
                        id
                    }
                }
                navigationNode(navHandle: "${handle}") {
                    navHandle
                }
            }`
}