/* eslint-disable react-hooks/rules-of-hooks */
import GraphCMS from "services/cms/GraphQL.service";
import GetAuxNav from "services/cms/queries/GetAuxNav";
import GetFeaturedOffers from 'services/cms/queries/GetFeaturedOffers';
import { GetFlyout } from 'services/cms/queries/GetFlyout';
import GetNav from "services/cms/queries/GetNav";
import GetPencilBanner from "services/cms/queries/GetPencilBanner";



/**
 * Dynamic Server Context Provider
 */

const cmsGraphQl = new GraphCMS();


// Flyout Nav
async function GetHeaderNav(id){
    const headerCategories = GetNav(id)
    return await cmsGraphQl.post(headerCategories).then((res) => res?.data?.navigationNodes)
}



// Prefetch our flyouts
async function PreFetchHeaderNav(id){
    const headerCategories = GetNav(id)
    return await cmsGraphQl.post(headerCategories).then((res) => res?.data)
}



async function GetFeaturedOffer(){
    const offers = GetFeaturedOffers()
    return await cmsGraphQl.post(offers).then((res) => res?.data.entry)
}



async function GetFlyoutNav(slug){
    const flyout = GetFlyout(slug)
    return await cmsGraphQl.post(flyout).then((res) => res?.data?.entries?.[0]?.flyoutBuilder);
}




// Top Aux Nav
async function GetHeaderAuxNav(){
    const auxNav = GetAuxNav()
    return await cmsGraphQl.post(auxNav).then((res) => res?.data)
}



async function GetPencilBannerData(){
    const pencilBanner = GetPencilBanner();
    return await cmsGraphQl.post(pencilBanner).then((res) => res?.data?.entries[0])
}




export {
    GetPencilBannerData,
    PreFetchHeaderNav,
    GetHeaderAuxNav,
    GetHeaderNav,
    GetFlyoutNav,
    GetFeaturedOffer
}